<template>
    <div class="check_code_pop">
        <van-popup v-model="ifcheckCodeshow" @close="close" :lock-scroll="true" :closeOnClickOverlay='true'
            style="background:none">
            <div class="payPage">
                <div class="content">
                    <div class="payText">
                        <span class="title">  {{ optType === 0 ? $t('m.fillIncode') : $t('m.expiredTips') }}</span>
                        <span class="payTip">{{ $t('m.fillcodeTips') }} </span>
                    </div>
                    <div class="fill_in_box">
                        <input class="fill_code" type="number" v-model.trim="code" placeholder-class="placeholderClass"
                         oninput="value=value.replace(/[^0-9]/, '').slice(0, 5)"/>
                    </div>
                    <div :class="['send_code', codeLen >= 5 ?'active_text':'']" @click="sendCode">{{ $t('m.sendAgain') }} 
                        <span v-if="ifShowdjs">({{ seconds }}&nbsp;{{ $t('m.seconds') }})</span></div>
                </div>
                <div class="buttom">
                    <span :class="['btn login_in', codeLen >= 5 ?'active':'noactive']" @click="login()">{{ $t('m.loginin') }}</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { checkauthenticationCode } from '../../assets/js/request'
import { $debounce } from '../../assets/js/commonData'
export default ({
    props: {
        //校验是否过期 0：未过期  1：已过期
        optType: {
            type: Number,
            default: 0
        },
        //选择搜索列表数据校验
        checkcodeType: {            
            type: Number,
            default: 1
        },
        rechargeType: {
            type: Number,
            default: 1
        },
        //控制弹窗外显隐
        checkCodeshow: {
            type: Boolean,
            default: false
        },
        //用户信息，记录用户填写的验证码
        userInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            ifcheckCodeshow: this.checkCodeshow,
            code: '',
            codeLen: 0,
            seconds: 60,
            timer: null,
            ifShowdjs: true,
            maxCount: 60
        }
    },
    
    created() {
        this.codeLen = this.code.length
        console.log('--------------------------- 填写验证码弹窗组件 -----------------------')
        console.log(JSON.parse(JSON.stringify(this.userInfo)), this.optType, '.................userInfo')
        console.log(JSON.parse(JSON.stringify(this.checkcodeType)), '.................checkcodeType')
        this.startDjs()

        if(this.optType == 1){
            this.ifShowdjs = false
            this.sendCode()
        }
    },
    watch: {
        'code'(nVal, oVal){
            this.codeLen = nVal.length
        },
        'seconds'(nVal,oVal){
            this.ifShowdjs = nVal === 0 ? false : true
        }
    },
    methods: {
        startDjs() {    
            this.timer = setInterval(()=>{
                this.seconds--                
                if(this.seconds <= 0) {
                    this.seconds = 0
                    clearInterval(this.timer)
                }
            },1000)
        },
        //再次发送
        sendCode:$debounce(function(e){
            console.log('.........再次发送')
            if(this.ifShowdjs) {
                return false
            }
            this.seconds = this.maxCount
            this.ifShowdjs = true
            this.startDjs()
            this.$emit('sendAgainback', {type:1, opt:1})
        }, 500),
        close() {
            this.$emit('closecheckcodePop')
        },
        //登录
        login() {
            if(this.code === '') {
                return false
            }
            checkauthenticationCode({uid: this.userInfo.user_id, code: this.code}).then(res => {
                //console.log(res , '...........登录校验')
                if(res.code === 0) {
                    let _params = {...this.userInfo, ...res.data}
                    if(this.checkcodeType === 3) {
                        //console.log('loginSuccesslistclickback....................')
                        this.$emit('loginSuccesslistclickback', _params)
                    } else {
                        //console.log('loginSuccessback........................')
                        this.userInfo.opt = 1
                        this.$emit('loginSuccessback', _params)
                    }                    
                    this.close()
                } else {
                    this.$toast(res.msg);
                }
            })
        }
    }
})
</script>

<style lang="scss" scoped>
.check_code_pop {
    .payPage {
        .content {
            .fill_in_box {
                margin-top: 16px;
                width: 100%;
                height: auto;
                // background: #F8F8F8;
                // border-radius: 40px 40px 40px 40px;
                // opacity: 1;
                input {                    
                    width: 98%;
                    height: 56px;
                    background: #F8F8F8;
                    border-radius: 40px 40px 40px 40px;
                    opacity: 1;
                    outline: none;
                    border: none;
                    text-indent: 5vw;
                }
                input::placeholder {
                    color: red;
                }
                input:focus{ 
                    outline:none;
                }
                .placeholderClass {
                    font-size: 18px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 18px;
                }
            }
            .send_code {
                margin-top: 16px;
                font-size: 16px;
                font-weight: 600;
                color: #B3B3B3;
                line-height: 19px;
            }
            .active_text {                
                color: #FF7875;
            }
        }
        .buttom {
            margin-top: 3vw;

            span {
                display: inline-block;
                width: 50vw;
                font-size: 16px;
                padding: 3vw 5vw;
                border-radius: 10vw;
                margin: 0 2vw;
            }

            .login_in {
                color: #fff;
            }
            .active {
                background-image: linear-gradient(to right, #ff9b67, #fb6c90);
            }
            .noactive {
                background-color: #ffe2e9;
            }
        }
    }
}</style>